import PropTypes from "prop-types";
import React from "react";
import { Box, Flex, Text, Heading, Button } from "rebass/styled-components";
import moment from "moment";
import "moment/locale/fr";

const CalendarDay = ({ children, onSelectDate, ...props }) => {
  onSelectDate = onSelectDate || function() {};
  return (
    <Flex
      width={1 / 7}
      p={2}
      {...props}
      sx={{
        ".inner": { opacity: 0.4 },
        "&.is-available .inner": { opacity: 1, backgroundColor: "muted", cursor: "pointer" },
        "&.today .inner": { opacity: 1, backgroundColor: "text", color: "background" },
        "&.selected .inner": { opacity: 1, backgroundColor: "success", color: "background" },
        "&.head .inner": { opacity: 1, fontWeight: "bold" }
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        width="50px"
        height="50px"
        className="inner"
        alignItems="center"
        justifyContent="center"
        sx={{ borderRadius: "30px" }}
        onClick={() => onSelectDate()}
      >
        <Text textAlign="center">{children}</Text>
      </Flex>
    </Flex>
  );
};
const CalendarItem = ({ timestamp, isSelected, isAvailable, ...props }) => {
  timestamp = timestamp || false;
  return (
    <CalendarDay
      {...props}
      className={
        (moment(timestamp, "X").format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          ? "today"
          : "") +
        " " +
        (isAvailable ? "is-available" : "") +
        " " +
        (isSelected ? "selected" : "")
      }
    >
      {timestamp ? moment(timestamp, "X").format("D") : ""}
    </CalendarDay>
  );
};

const Calendar = ({ date, monthDate, availableDays, onSelectDate, onChangeMonth, ...props }) => {
  availableDays = availableDays || [];
  onSelectDate = onSelectDate || function() {};
  onChangeMonth = onChangeMonth || function() {};
  const startOfMonth = moment(monthDate, "X").startOf("month");
  const monthNum = startOfMonth.format("M");
  const nextmonthNum = moment(monthDate, "X")
    .startOf("month")
    .add(1, "month")
    .format("M");
  const calendarWeeks = [];

  while (startOfMonth.format("M") === monthNum) {
    var weekNum = startOfMonth.format("w");
    var week = {};
    while (startOfMonth.format("w") === weekNum) {
      if (startOfMonth.format("M") !== nextmonthNum) {
        week[startOfMonth.format("dd")] = startOfMonth.format("X");
      }
      startOfMonth.add(1, "days");
    }
    calendarWeeks.push(week);
  }
  const daysKey = ["lu", "ma", "me", "je", "ve", "sa", "di"];
  const canGoPreviousMonth =
    moment(monthDate, "X")
      .startOf("month")
      .format("YYYYM") !== moment().format("YYYYM");
  return (
    <Box>
      <Flex justifyContent="space-between">
        <Heading sx={{ textTransform: "capitalize" }}>
          {moment(monthDate, "X")
            .startOf("month")
            .format("MMMM YYYY")}
        </Heading>
        <Flex>
          <Button
            variant="ninja"
            sx={{
              opacity: !canGoPreviousMonth ? "0.3 !important" : 1,
              cursor: !canGoPreviousMonth ? "not-allowed" : "pointer"
            }}
            onClick={() => {
              if (canGoPreviousMonth) {
                onChangeMonth(
                  moment(monthDate, "X")
                    .startOf("month")
                    .subtract(1, "month")
                    .format("X")
                );
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </Button>
          <Button
            variant="ninja"
            onClick={() =>
              onChangeMonth(
                moment(monthDate, "X")
                  .startOf("month")
                  .add(1, "month")
                  .format("X")
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="butt"
              strokeLinejoin="arcs"
            >
              <path d="M9 18l6-6-6-6" />
            </svg>
          </Button>
        </Flex>
      </Flex>

      <Flex mt={6}>
        <CalendarDay className="head">L</CalendarDay>
        <CalendarDay className="head">M</CalendarDay>
        <CalendarDay className="head">M</CalendarDay>
        <CalendarDay className="head">J</CalendarDay>
        <CalendarDay className="head">V</CalendarDay>
        <CalendarDay className="head">S</CalendarDay>
        <CalendarDay className="head">D</CalendarDay>
      </Flex>
      {calendarWeeks.map((week, key) => (
        <Flex key={`week-${key}`}>
          {daysKey.map((dayKey, ind) => {
            var isAvailable = availableDays.indexOf(week[dayKey]) > -1;
            return (
              <CalendarItem
                key={`cal-item-${ind}-week-${key}`}
                timestamp={week[dayKey]}
                isSelected={week[dayKey] === date}
                isAvailable={isAvailable}
                onSelectDate={() => {
                  if (isAvailable) {
                    onSelectDate(week[dayKey]);
                  }
                }}
              />
            );
          })}
        </Flex>
      ))}
    </Box>
  );
};

Calendar.propTypes = {
  availableDays: PropTypes.array,
  date: PropTypes.any,
  onSelectDate: PropTypes.func
};

export default Calendar;
