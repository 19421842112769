import React from "react";
import { Router } from "@reach/router";
import { connect } from "react-redux";
import Rdv from "../components/rdv";
const App = () => {
  return (
    <div className="app">
      <Router>
        <Rdv path="rdv/:nid" />
      </Router>
    </div>
  );
};

export default connect()(App);
