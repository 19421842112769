import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "react-awesome-spinners";
import { Box, Text } from "rebass/styled-components";
import { withTheme } from "styled-components";

const Loader = ({ message, theme }) => {
  message = message || "Chargement en cours";
  return (
    <Box py={4} textAlign="center">
      <Spinner color={theme.colors.primary} />
      <Box pt={4} textAlign="center">
        <Text>{message}</Text>
      </Box>
    </Box>
  );
};

Loader.propTypes = {
  message: PropTypes.string,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      primary: PropTypes.any
    })
  })
};
export default withTheme(Loader);
