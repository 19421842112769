import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Text, Button } from "rebass/styled-components";
import moment from "moment";
import Loader from "../UI/Loader";
import { connect } from "react-redux";
import { calendar_search } from "../../redux/actions/rdv";
import "moment/locale/fr";
import Container from "../UI/Container";
import Calendar from "./Calendar";
import Markdown from "../UI/Markdown";
import CustomImage from "../UI/CustomImage";
import TransitionGroup from 'react-transition-group/TransitionGroup';
import Fade from "react-reveal/Fade"
import Flip from "react-reveal/Flip"
import Zoom from "react-reveal/Zoom"

const groupTimestampByDay = dispos => {
  return dispos.reduce((result, currentValue) => {
    var dayTimestamp = moment(currentValue, "X")
      .startOf("day")
      .format("X");
    (result[dayTimestamp] || (result[dayTimestamp] = [])).push(currentValue);
    return result;
  }, {});
};

const Rdv = ({ nid, dispatch, rdv, ...props }) => {
  // console.log(props);
  const [showCalendar, setCalendar] = useState(false);
  const [dispos, setDispos] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment()
      .startOf("day")
      .format("X")
  );
  const [poi, setPoi] = useState(false);
  const [selectedHour, setSelectedHour] = useState(false);
  const [selectedMonthDate, setSelectedMonthDate] = useState(
    moment()
      .startOf("month")
      .format("X")
  );
  useEffect(() => {
    fetch("/pois/" + nid + ".json")
      .then(async response => {
        const data = await response.json();
        // check for error response
        if (!response.ok) {
          // setPoi(false);
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }
        setPoi(data);
      })
      .catch(error => {
        setPoi(false);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  useEffect(() => {
    if (poi) {
      const defaultdata = {
        calendarid: "primary",
        startDay: selectedMonthDate,
        ammountDays: 30,
        leadtime: 0
      };

      dispatch(calendar_search({ ...defaultdata, ...poi }));
    }
  }, [selectedMonthDate, poi]);
  useEffect(() => {
    setDispos(groupTimestampByDay(rdv.dispos));
  }, [rdv]);

  if (!poi && !dispos) {
    return <Loader />;
  }
  return (
    <Flex 
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh" >
      <Container p={5} height={"80%"}>
        <Flex maxHeight={"100%"}>
          <Box width={[1,1,1, 23 / 100]}  bg="#FFF" p={5} minHeight={"100%"} maxHeight={"100%"} overflow={"hidden"}>
            <CustomImage
              height="50px !important"
              img={"/img/logo.svg"}
              alt={"Logo rdv.nc"}
            />
            <Text fontSize={2} py={5}>Votre rendez-vous avec</Text>
            <Heading as="h2">{poi.title}</Heading>
            <Flex alignItems={"end"} py={5}>
              <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"> 
                    <g fill="currentColor">
                        <path d="M0,10 C0,15.5139999 4.48600006,20 10,20 C15.5139999,20 20,15.5139999 20,10 C20,4.48600006 15.5139999,0 10,0 C4.48600006,0 0,4.48600006 0,10 Z M2,10 C2,5.58900023 5.58900023,2 10,2 C14.4109993,2 18,5.58900023 18,10 C18,14.4109993 14.4109993,18 10,18 C5.58900023,18 2,14.4109993 2,10 Z M9,5 L9,11 L15,11 L15,9 L11,9 L11,5 L9,5 Z"></path>
                    </g>
                </svg>
              <Text fontSize={2} pl={3} lineHeight={"1.5"}>1h</Text>
            </Flex> 
            <Markdown>{poi.desc}</Markdown>
            <Button
              display={["inline-block","inline-block","inline-block","none"]}
              onClick={() => setCalendar(true)}
            >
              Sélectionnez une date et une heure
            </Button>
          </Box>
          <Box 
            width={[1,1,1, 77 / 100]}
            display={showCalendar?"block":["none","none","none","block"]}
            minHeight={"100%"}
            maxHeight={"100%"}
            bg="#f6f6f6"
            sx={{position:["absolute","absolute","absolute","relative"]}}
          >
            <Box px={5} display={["none","none","none","block"]}>
              <Box  sx={{'border-bottom': "1px solid #DADADA"}}>
                <Heading as="h2" pt={10} pb={8}>Selectionnez une date et une heure</Heading>
              </Box>
            </Box>
            <Flex width={"100%"}>
              <Box
                width={[1,1,1,2 / 3]}
                p={5}
              >
                <Box sx={{ position: "relative" }}>
                  <Box opacity={rdv.loading ? 0.2 : 1}>
                    <Calendar
                      date={selectedDate}
                      monthDate={selectedMonthDate}
                      availableDays={Object.keys(dispos)}
                      onSelectDate={date => {
                        setSelectedDate(date);
                        setSelectedHour(false);
                      }
                      }
                      onChangeMonth={monthDate => {
                        setSelectedMonthDate(monthDate);
                        setSelectedDate(Object.keys(dispos)[0]);
                        setSelectedHour(false);
                      }}
                    />
                  </Box>
                  {rdv.loading && (
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      height="100%"
                      sx={{ position: "absolute", top: 0, left: 0 }}
                    >
                      <Loader />
                    </Flex>
                  )}
                </Box>
              </Box>
              <Box
                width={[2 / 3,2 / 3,2 / 3,1 / 3]}
                pb={5}
                sx={{position:["absolute","absolute","absolute","relative"], right: ["0","0","0","unset"]}}
                bg="#f6f6f6"
              >
                
                <Box minWidth="100%" p={5} sx={{'border-left': "1px solid #DADADA"}}>
                  {dispos && (
                    <Box>
                      {Object.entries(dispos)
                        .filter(([day, hours], i) => selectedDate === day)
                        .map(([day, hours], i) => (
                          
                          <Box key={`day-${i}`}>
                            <Fade duration={300} opposite spy={day} appear>
                              <Heading as="h4" pb={5} sx={{ textTransform: "capitalize" }}>
                              {moment(day, "X")
                                  .startOf("day")
                                  .format("dddd")}
                                <br/>
                                {moment(day, "X")
                                  .startOf("day")
                                  .format("D MMMM")}
                              </Heading>
                            </Fade>
                            <Box>
                              
                              
                            <Zoom right spy={day} appear cascade><div>
                                    {hours.map((timestamp, timekey) => (
                                      
                                        <Flex key={`time${timestamp}`} py={1}>
                                          
                                          <Button
                                            width={timestamp === selectedHour ? 1 / 3 : 1}
                                            onClick={() => setSelectedHour(timestamp)}
                                            variant={timestamp === selectedHour ? "primary" : "outline"}
                                          >
                                            <span>{moment(timestamp, "X").format("HH:mm")}</span>
                                          </Button>
                                          {timestamp === selectedHour && (
                                            <Box pl={1} width={2 / 3}>
                                              <Flip duration={500} delay={200}>
                                              <Button width={1 / 1} variant="success">
                                                Valider
                                              </Button>
                                              </Flip>
                                            </Box>
                                          )}
                                        </Flex>
                                      
                                    ))}
                                 </div></Zoom> 
                                
                              
                            </Box>
                            
                          </Box>
                          
                        ))}
                    </Box>
                  )}
                </Box>
                
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Flex>
  );
};

Rdv.propTypes = {
  dispatch: PropTypes.func,
  rdv: PropTypes.any
};

const mapStateToProps = state => state;
export default connect(mapStateToProps)(Rdv);
